import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "hasFooter" }
const _hoisted_2 = { class: "d-flex justify-content-between my-3" }
const _hoisted_3 = { class: "fs-14 text-cyan text-uppercase" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "twoBlocksContainer justify-content-between"
}
const _hoisted_6 = { class: "infoContainer pt-4" }
const _hoisted_7 = { class: "text-uppercase fs_14 greyBlue ln_12 title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.comms, (category, i) => {
      return (_openBlock(), _createBlock("div", { key: i }, [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(category.category), 1)
        ]),
        (category.items.length == 0)
          ? (_openBlock(), _createBlock("div", _hoisted_4, [
              _createVNode(_component_localized_text, {
                localizedKey: 'app_nessunacommunityinquestacategoria',
                text: 'Nessuna community in questa categoria',
                class: "fs_14 greyBlue d-block ln_12 mt-5 text-center"
              })
            ]))
          : (_openBlock(), _createBlock("div", _hoisted_5, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(category.items, (item, i) => {
                return (_openBlock(), _createBlock("div", {
                  class: "block shadowRadiusCard discoverCard",
                  key: i,
                  onClick: ($event: any) => (_ctx.openCommunity(item))
                }, [
                  _createVNode("div", {
                    class: "backgroundImage",
                    style: 'background-image: url(\'' + _ctx.$store.state.getUserImage(item.identifier) + '\');'
                  }, null, 4),
                  _createVNode("div", _hoisted_6, [
                    _createVNode("span", _hoisted_7, _toDisplayString(item.name), 1)
                  ])
                ], 8, ["onClick"]))
              }), 128))
            ]))
      ]))
    }), 128))
  ]))
}