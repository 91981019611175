
import { Options, Vue } from 'vue-class-component';
import { FieraClient } from '@/services/Services';
import store from '@/store';
import * as Const from '@/const';
import * as VM from '@/viewModel';
import * as OM from '@/model';

@Options({})
export default class CommunityInfo extends Vue {

    comms: OM.CategoryItemsOfNameSlugIdentifier[] = [];

    created(){
        FieraClient.getCommunitiesOfFieraBySlug(this.$route.params.slug.toString())
        .then(x => {
            this.comms = x;
        })
    }

    openCommunity(item: OM.NameSlugIdentifier){
        this.$router.push('/communities/' + item.slug);
    }

}

